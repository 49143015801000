import React from 'react';
import { Button } from '../Buttons';
import { useDispatch } from 'react-redux';
import { openSideModal } from '../../slices/sideModalSlice';
import { SideModalTabs } from '../SideModal';

type TButtonProps = {
  minWidth?: number | 'auto';
  maxWidth?: string | 'auto';
};

const ChooseStoreButton = ({ maxWidth, minWidth }: TButtonProps) => {
  const dispatch = useDispatch();
  return (
    <Button
      fullwidth
      primary
      maxWidth={maxWidth}
      minWidth={minWidth}
      onClick={event => {
        event.preventDefault();
        dispatch(openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER));
      }}
    >
      Välj butik
    </Button>
  );
};

export default ChooseStoreButton;
