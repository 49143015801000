import React from 'react';
import { dateAsFormat } from '../../lib/delivery';
type TAvailabilityText = {
  availableFrom?: string;
  availableTo?: string;
  expired?: boolean;
};
type TAvailabilityTextProps = {
  availability: TAvailabilityText;
  isCateredMeal?: boolean;
};

const AVAILABILITY_TEXT = {
  PRODUCT: {
    availableFrom: 'Tillgänglig',
    availableTo: 'Tillgänglig till'
  },
  CATERED_MEALS: {
    availableFrom: 'Köp nu, hämta från:',
    availableTo: 'Köp senast'
  },
  EXIPRED: 'Utgått'
};

const AvailabilityText = ({
  availability,
  isCateredMeal
}: TAvailabilityTextProps) => {
  const { availableFrom, availableTo, expired } = availability;

  const availableFromText = isCateredMeal
    ? AVAILABILITY_TEXT.CATERED_MEALS.availableFrom
    : AVAILABILITY_TEXT.PRODUCT.availableFrom;
  const availableToText = isCateredMeal
    ? AVAILABILITY_TEXT.CATERED_MEALS.availableTo
    : AVAILABILITY_TEXT.PRODUCT.availableTo;
  return (
    <span className="c-availability__text">
      {availableFrom ? (
        <>
          {availableFromText} {dateAsFormat(new Date(availableFrom), 'd/M')}
        </>
      ) : availableTo ? (
        <>
          {availableToText} {dateAsFormat(new Date(availableTo), 'd/M')}
        </>
      ) : expired ? (
        <>{AVAILABILITY_TEXT.EXIPRED}</>
      ) : null}
    </span>
  );
};

export default AvailabilityText;
