import React from 'react';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { getNextAvailabilityDateForStore } from '../../lib/availability/availability';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import { AvailabilityImage } from './AvailabilityImage';
import AvailabilityText from './AvailabilityText';
import { IAvailabilityProps } from './availability.interface';
import './Availability.scss';

const Availability: React.FC<IAvailabilityProps> = ({
  availability,
  isCateredMeal
}) => {
  const storeNumber = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );

  const productAvailability = getNextAvailabilityDateForStore(
    availability,
    storeNumber
  );
  if (!productAvailability) {
    return null;
  }

  return (
    <div className="c-availability">
      {productAvailability.availableFrom ||
      productAvailability.availableTo ||
      productAvailability.expired ? (
        <AvailabilityImage />
      ) : null}
      <AvailabilityText
        availability={productAvailability}
        isCateredMeal={isCateredMeal}
      />
    </div>
  );
};

export default Availability;
