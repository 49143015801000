import { format } from 'date-fns';
import React from 'react';

const ProductOfferEndDate = ({ product }) => {
  const handleShowEndDate = promotions => {
    const date = new Date();
    const fromDate = new Date(promotions.from);
    const toDate = new Date(promotions.to);

    if (date < fromDate) {
      return null;
    }

    if (date >= fromDate && date <= toDate) {
      return `Erbjudandet gäller ${format(fromDate, 'd/M')} - ${format(
        toDate,
        'd/M'
      )}`;
    }

    return null;
  };

  return (
    <div>
      {product && product?.price?.promotions2?.[0] && (
        <span className="c-productdetails__offer-details">
          {handleShowEndDate(product.price?.promotions2?.[0])}
        </span>
      )}
    </div>
  );
};

export default ProductOfferEndDate;
