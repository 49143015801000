import React, { useState } from 'react';
import cs from 'classnames';
import PriceTag from '../../../components/PriceTag';
import { Link } from 'react-router-dom';
import BuyButton from '../../../components/BuyButton';
import { getProductDescription, setImage } from '../../../lib/product';
import { IExtendedProduct } from '../../../types/storefront/product';
import './ProductSuggestion.scss';
import { SearchListItem } from '@citygross/components';
import Image from '../../../components/Image';
import { LIMITED_DEAL_TAG } from '../../../components/ProductCard/ProductCard';
import FallbackImage from '../../../components/assets/produkt-fallback.svg';

type TProductSuggestion = {
  esalesClickTrigger?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  esalesClickTriggerOnAddingToCart?: Function;
  product: IExtendedProduct;
  setKeepOpen: () => void;
};

export const ProductSuggestion = ({
  esalesClickTrigger,
  esalesClickTriggerOnAddingToCart,
  product,
  setKeepOpen
}: TProductSuggestion) => {
  const {
    images,
    name,
    price,
    url,
    returnablePackageDepositInformation,
    unit
  } = product;

  const image = setImage(images, 0);
  const description = getProductDescription(product);

  const [showMax, setShowMax] = useState<boolean>(false);

  const hasLimitedDeal = !!product?.tags?.find(
    tag =>
      tag.name === LIMITED_DEAL_TAG.name &&
      tag.namespace === LIMITED_DEAL_TAG.namespace
  );

  return (
    <Link
      to={{
        pathname: url,
        state: { flyout: true, title: ' ', nopadding: true, tight: false }
      }}
      onClick={e => {
        setKeepOpen();
        esalesClickTrigger && esalesClickTrigger(e);
      }}
    >
      <div className="c-productsuggestion-card__container">
        <SearchListItem
          item={{
            description: description,
            image: image.url,
            name: name
          }}
          markings={product.mappedMarkings
            ?.filter(x => x.code === 'RestrictedToSell18')
            ?.map(marking => ({
              image: (
                <div style={{ height: 20, width: 20 }}>
                  <Image
                    src={marking.image?.url}
                    alt={marking?.altText}
                    noLazy
                  />
                </div>
              ),
              backgroundColor: 'rgba(0,0,0,0.9)',
              tooltipText: marking?.altText
            }))}
          price={
            <PriceTag
              price={price}
              hasLimitedDeal={hasLimitedDeal}
              unit={unit}
              isOnlyForMembers={false}
              pant={returnablePackageDepositInformation?.description === 'Pant'}
              isInSearch
            />
          }
          button={
            <div className={cs('c-productsuggestion__buy', 'in-dropdown')}>
              <BuyButton
                product={product}
                esalesClickTriggerOnAddingToCart={
                  esalesClickTriggerOnAddingToCart
                }
                minWidth="120px"
                small
                setShowMax={setShowMax}
              />
              <span className="max-amount" style={{ opacity: showMax ? 1 : 0 }}>
                Max antal uppnått
              </span>
            </div>
          }
          fallBackImage={FallbackImage}
        />
      </div>
    </Link>
  );
};
