/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setImage } from '../../../../lib/product';
import { getCartItemsData } from '../../../../lib/newCartPreview.js';
import './NewCartPreview.scss';
// @ts-ignore
import defaultImg from '../../../assets/produkt-fallback.svg';
import cs from 'classnames';
import RoundSpinner from '../../../RoundSpinner';
import { resetNewCart } from '../../../../actions/newCartPrep/sync';
import { CgButton, EButtonVariant } from '@citygross/components';
// TODO: Should not be site coupled, adapt for zip.

interface IProps {
  goBack?: Function;
  handleSubmit?: any;
  inDeliveryModal?: boolean;
}

const NewCartPreview: React.FC<IProps> = ({
  goBack,
  handleSubmit,
  inDeliveryModal
}) => {
  const dispatch = useDispatch();

  const cartLoading = useSelector((state: any) => state.cart.meta.refreshing);
  const newCartSortiment = useSelector((state: any) => state.newCartPrep);
  const [newCartRefs, setNewCartRefs] = useState<any>();
  const cart = useSelector((state: any) => state.cart);
  const [loading, setLoading] = useState(false);
  const [loadingNewCart, setLoadingNewCart] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (newCartSortiment.newCart) {
      getCartItemsData(newCartSortiment.newCart)
        .then(syncedCart => ({
          ...syncedCart,
          date: '',
          paymentDate: '',
          isPaid: null
        }))
        .then(res => {
          let productRefs: any = {};
          const itemsRemapped = res?.items?.map(item => {
            productRefs = { ...productRefs, [item.item.id]: item.item };
            return (item.item.id = {
              ...item[item.item.id],
              totalCount: item[item.item.id].quantity.value
            });
          });

          setNewCartRefs({
            ...res,
            items: {
              items: itemsRemapped,
              productRefs
            }
          });
          setLoading(false);
        });
    }
  }, [newCartSortiment?.newCartId]);

  return (
    <div className="c-newCartPreview">
      <div className="c-newCartPreview__info">
        <h2 className="c-newCartPreview__title">Vissa varor tas bort!</h2>
        <p>
          Din varukorg innehåller varor som saknas i sortimentet för butiken du
          valt. Dessa kommer tas bort.
        </p>
        <p>
          Priser och erbjudanden kan skilja mellan butiker. Prissättning sker i
          den butik du byter till.
        </p>
      </div>
      <div className="c-newCartPreview__productwrap">
        <div className="c-newCartPreview__products">
          {loading && (
            <div className="c-newCartPreview__loading">
              <RoundSpinner />
            </div>
          )}
          {!loading &&
            newCartSortiment?.cartDiff &&
            Object.entries(newCartSortiment?.cartDiff)?.map((entries: any) => {
              const [entryType, entryItems] = entries;
              if (entryItems && entryItems?.length < 1) return null;
              return entryItems.map(product => {
                let refPath = cart;

                let imgPath: undefined | string;
                let itemRef;
                let productName;
                let cateringRef;

                if (product.subType === 'Item') {
                  itemRef = refPath?.items?.productRefs[product.itemNo];
                  imgPath = itemRef?.images
                    ? setImage(itemRef?.images, 0).url
                    : defaultImg;

                  productName =
                    itemRef?.item?.name || itemRef?.name || product?.name;
                }

                if (
                  product.subType === 'RecipeBag' &&
                  product.subTypeId !== 'CateredMeal'
                ) {
                  itemRef = newCartRefs?.recipes?.find(recipe => {
                    return recipe?.recipeId === product?.recipeId;
                  });
                  imgPath = itemRef?.item?.images
                    ? setImage(itemRef?.item?.images, 0, 'recipes').url
                    : defaultImg;
                  productName =
                    itemRef?.item?.name || itemRef?.name || product?.name;
                }

                if (product.subTypeId === 'CateredMeal') {
                  cateringRef =
                    refPath?.cateredMeals?.cateredMealsRefs?.[product.itemNo];
                  imgPath = cateringRef?.images
                    ? setImage(cateringRef?.images, 0, 'products').url
                    : cateringRef?.image || defaultImg;

                  productName = product?.name;
                }

                return (
                  <div className="c-newCartPreview__product" key={product.id}>
                    <div className="c-newCartPreview__productimage">
                      <img src={imgPath} alt="Product" />
                    </div>
                    <div className="c-newCartPreview__description">
                      <p>{productName}</p>
                      {itemRef?.descriptiveSize ? (
                        <p className="c-newCartPreview__descriptive">
                          {itemRef?.descriptiveSize}
                        </p>
                      ) : null}
                      {product.subTypeId === 'CateredMeal' &&
                      cateringRef?.name &&
                      cateringRef?.name !== 'Standard' ? (
                        <p className="c-newCartPreview__descriptive">
                          {cateringRef?.name}
                        </p>
                      ) : null}
                    </div>
                    <div className="c-newCartPreview__priceSection ">
                      <div
                        className={cs('c-newCartPreview__description', {
                          ['c-newCartPreview__priceDiff']:
                            entryType === 'removedItems'
                        })}
                      >
                        {product.subTypeId === 'CateredMeal' && (
                          <>
                            <p className="c-newCartPreview__descriptive">
                              {cateringRef?.unit === 'per person' &&
                              cateringRef?.quantityFrom
                                ? cateringRef?.quantityFrom
                                : product?.quantity?.value}{' '}
                              {cateringRef?.unit === 'per person'
                                ? 'port'
                                : 'st'}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              });
            })}
        </div>
      </div>
      <div className="c-newCartPreview__buttons">
        {goBack && (
          <CgButton
            onClick={async () => {
              dispatch(resetNewCart());
              goBack();
            }}
            variant={EButtonVariant.secondary}
          >
            Avbryt
          </CgButton>
        )}

        <CgButton
          onClick={async () => {
            setLoadingNewCart(true);
            handleSubmit && (await handleSubmit(cart.meta.id));
          }}
          loading={cartLoading || loading || loadingNewCart}
        >
          {inDeliveryModal ? 'Byt butik' : 'Acceptera'}
        </CgButton>
      </div>
    </div>
  );
};

export default NewCartPreview;
