import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticatedSelector } from '../../selectors/user';
import { clearErrors } from '../../actions/cart';
import { open as openModal } from '../../actions/confirmModal';
import {
  toggleInfoModalActive,
  toggleModalActive
} from '../../actions/assortments';
import { Button } from '../Buttons';
import ChangeQuantity from '../ChangeQuantity';
import { CSSTransition } from 'react-transition-group';
import './BuyButton.scss';
import {
  determineDeliveryOptions,
  activeStoreNoSelector
} from '../../selectors/assortments';
import {
  addToCart,
  increaseItemQuantity,
  decreaseItemQuantity,
  cartLoading
} from '../../actions/cart';

import { redirectToLogin } from '../../actions/app';
import { getCartItems } from '../../selectors/cart/cartSelector';
import { AppState } from '../../reducers';
import { IExtendedProduct } from '../../types/storefront/product';
import { openSideModal } from '../../slices/sideModalSlice';
import { SideModalTabs } from '../SideModal';
import ChooseStoreButton from './ChooseStoreButton';
import LoginPromptButton from './LoginPromptButton';

interface IGetButtonText {
  sellable?: boolean;
  noAvailability?: boolean;
  hasPrice?: boolean;
  buttonText?: string;
}
export const getButtonText = ({
  sellable,
  noAvailability,
  hasPrice,
  buttonText
}: IGetButtonText) => {
  if (!sellable) {
    return 'Utgått';
  }

  if (noAvailability || !hasPrice) {
    return 'Tillfälligt slut';
  }

  return buttonText || 'Lägg till';
};

interface IBuyButton {
  product: IExtendedProduct;
  buttonText?: string;
  esalesClickTriggerOnAddingToCart?: Function;
  fullWidth?: boolean;
  minWidth?: number | string;
  noAddButton?: boolean;
  noMarginQty?: boolean;
  small?: boolean;
  primaryButton?: boolean;
  changedDeliveryOfferCopy?: string;
  setShowMax?: Dispatch<SetStateAction<boolean>>;
}

const BuyButton: React.FC<IBuyButton> = ({
  product,
  primaryButton,
  noMarginQty,
  noAddButton,
  fullWidth,
  minWidth,
  small,
  buttonText,
  esalesClickTriggerOnAddingToCart,
  setShowMax
}) => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state: AppState) =>
    authenticatedSelector(state)
  );
  const debounceQueue = useSelector(
    (state: any) => state.cart.items.debounceQueue.queue
  );
  const imediateQueue = useSelector(
    (state: any) => state.cart.items.imediateQueue
  );
  const failedItemAdds = useSelector(
    (state: any) => state.cart.items.failedItemAdds
  );
  const deliveryOptions = useSelector((state: AppState) =>
    determineDeliveryOptions(state)
  );
  const activeAssortment = useSelector((state: AppState) =>
    activeStoreNoSelector(state)
  );
  const cartItem = useSelector((state: AppState) =>
    getCartItems(state).find(x => x.itemNo === product?.id)
  );
  const orderIsLoading = useSelector((state: AppState) => state.order.loading);
  const cartIsLoading = useSelector((state: any) => state.cart.meta.loading);
  const cartIsRefreshing = useSelector(
    (state: any) => state.cart.meta.refreshing
  );
  const [quantityState, setQuantityState] = useState<number>(
    cartItem?.quantity?.value ?? 0
  );
  const [animationState, setAnimationState] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [pendingRequestState, setPendingRequestState] = useState<boolean>(
    false
  );
  const itemIsRefreshing =
    (debounceQueue.find(item => item.itemNo === product.id) &&
      cartIsRefreshing) ||
    imediateQueue?.find(item => item === product.id);
  useEffect(() => {
    if (!product) {
      return;
    }

    if (
      !pendingRequestState &&
      !debounceQueue.find(item => item.itemNo === product.id) &&
      cartItem
    ) {
      setQuantityState(cartItem?.quantity.value);
      setPendingRequestState(false);
      setLoadingState(false);
    }

    //If product(s) failed to be added
    if (failedItemAdds.find(itemId => itemId === product.id)) {
      onError();
      clearErrors();
    }
  }, [cartItem, product, debounceQueue, itemIsRefreshing]);

  useEffect(() => {
    setQuantityState(cartItem?.quantity.value);
  }, [cartItem]);

  const onError = () => {
    setQuantityState(cartItem?.quantity.value);
    setPendingRequestState(false);
    setLoadingState(false);

    dispatch(
      openModal({
        title: 'Hoppsan, något gick fel!',
        body: 'En eller flera produkter kunde inte läggas till',
        confirmLabel: 'Stäng',
        cancelLabel: null,
        onConfirmClick: () => {}
      })
    );
  };

  const addToCartFn = isAuthenticated
    ? () => addToCart(product)
    : redirectToLogin;

  const handleQuantityChange = (addToCartfn, quantity, event) => {
    if (event) {
      event.stopPropagation();
    }

    if (isAuthenticated && esalesClickTriggerOnAddingToCart) {
      esalesClickTriggerOnAddingToCart();
    }
    //Prevent negative count on product
    const newQuantity = quantity < 0 ? 0 : quantity;
    const currentQuantity = !pendingRequestState
      ? quantityState || 0
      : cartItem?.quantity.value || 0;

    //Check if we go from zero to many or many to zero in count
    // TODO: Linter doesn't like this as one row without surrounding (),
    // however formatter removes them
    const currentLessThan1 = currentQuantity < 1;
    const newQuantityGrt0 = newQuantity > 0;
    const blockKeyPress = !!(currentLessThan1 === newQuantityGrt0);

    if (isAuthenticated) {
      setQuantityState(newQuantity);
      setLoadingState(blockKeyPress);
      setAnimationState(blockKeyPress);
      setPendingRequestState(!!currentQuantity);
    }

    //Only catch promise if we go from 1 to 0 in count

    if (currentQuantity) {
      catchPromise(addToCartfn, newQuantity);
    } else {
      if (isAuthenticated) {
        dispatch(cartLoading(true));
      }
      dispatch(addToCartfn(cartItem?.id, Math.abs(newQuantity))).catch(() =>
        onError()
      );
    }
  };

  const catchPromise = (fn, newQuantity) => {
    dispatch(fn(cartItem.id, Math.abs(newQuantity)))
      .then(() => {
        setLoadingState(false);
        setPendingRequestState(!!false);
      })
      .catch(() => onError());
  };

  if (activeAssortment === null) {
    return <ChooseStoreButton maxWidth={!fullWidth ? '180px' : 'auto'} />;
  }

  if (!deliveryOptions.canAddProductsOrRecipes) {
    return (
      <Button
        fullwidth
        primary
        onClick={event => {
          event.preventDefault();
          dispatch(openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER));
        }}
      >
        Byt leveranssätt
      </Button>
    );
  }
  if (!isAuthenticated) {
    return <LoginPromptButton maxWidth={!fullWidth ? '180px' : 'auto'} />;
  }

  return (
    <div className="buy-button">
      {noAddButton ? null : (
        <CSSTransition
          in={!quantityState}
          timeout={{ appear: 200, enter: 200, exit: 0 }}
          mountOnEnter
          unmountOnExit
          classNames="addtocart-animation"
          onEntered={() => setAnimationState(false)}
        >
          <div className="addtocart active">
            <Button
              fullwidth={fullWidth}
              disabled={
                false || //temporaryUnavailable
                !product.available ||
                !product.sellable ||
                cartItem?.quantity?.value ||
                !product.price
                  ? true
                  : false ||
                    loadingState ||
                    animationState ||
                    pendingRequestState
              }
              primary={primaryButton}
              onClick={event => {
                event.preventDefault();
                handleQuantityChange(addToCartFn, 1, event);
              }}
              loading={
                (loadingState && !quantityState) ||
                orderIsLoading ||
                cartIsLoading ||
                pendingRequestState ||
                itemIsRefreshing
              }
              //@ts-ignore
              minWidth={minWidth ? minWidth : 'auto'}
            >
              {getButtonText({
                sellable: product.sellable,
                noAvailability: false,
                hasPrice: product.price
                  ? true
                  : product?.prices?.find(
                      p => p?.storeNumber === activeAssortment
                    )
                  ? true
                  : false,
                buttonText
              })}
            </Button>
          </div>
        </CSSTransition>
      )}
      <CSSTransition
        in={!!quantityState || noAddButton}
        timeout={{ appear: 100, enter: 200, exit: 0 }}
        mountOnEnter
        unmountOnExit
        classNames="changeqty-animation"
        onEntered={() => setAnimationState(false)}
      >
        <ChangeQuantity
          count={quantityState}
          noMargin={noMarginQty}
          onIncremental={() => {
            handleQuantityChange(increaseItemQuantity, quantityState + 1, null);
          }}
          onDecremental={() => {
            handleQuantityChange(decreaseItemQuantity, quantityState - 1, null);
          }}
          loading={loadingState || animationState}
          countLimit={product?.maximumQuantity || 25}
          small={small}
          setShowMax={setShowMax}
        />
      </CSSTransition>
    </div>
  );
};

export default BuyButton;
